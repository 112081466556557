import React from 'react';
import { Paper } from '@mui/material'
import theme from '../../../theme';

function Item(item: { id: number, image: string | undefined; })
{
    const baseURL = process.env.REACT_APP_API_URL;
    return (
        <Paper style={{ width: '100%', height: theme.breakpoints.down('md') ? '530px' : 'auto' }}>
            <img 
                style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                }}
                src={`${baseURL}/${item.image}`} 
                alt="Auto parts"/>
        </Paper>
    )
}

export default Item;