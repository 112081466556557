import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';

const icons = [
  { icon: './auto_suv_vehicle_car_automobile_icon.png', title: '190K+ Vehicle Type', style: {width: '80px', height: 'auto'} }, 
  { icon: './disc.png', title: '9.8M+ Article Data', style: {width: '70px', height: 'auto', paddingTop: '5px', paddingBottom: '5px'} },
  { icon: './brands.png', title: '1000+ Brands', style: {width: '80px', height: 'auto', paddingTop: '20px', paddingBottom: '20px'} },
  { icon: './add_user_human_person_plus_icon.png', title: 'Unlimited Users', style: {width: '80px', height: 'auto'} },
  { icon: './bag_buy_cart_sell_shop_icon.png', title: 'Online Experience', style: {width: '80px', height: 'auto'} },
];

const Login = () => {
  const baseURL = process.env.REACT_APP_API_URL;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [warning, setWarning] = useState("");
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();

  const validateForm = React.useCallback(() => {
    if (username.length === 0 || password.length === 0) {
      setError("");
      setWarning("Username and password are required");
      return false;
    }
    setWarning("");
    return true;
  }, [username, password]);

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
  
    const formDetails = new URLSearchParams();
    formDetails.append("username", username);
    formDetails.append("password", password);
  
    try {
      const response = await fetch(`${baseURL}/auth/token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: formDetails,
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.access_token);
        localStorage.setItem("user", JSON.stringify(data.user));

        // Redirect to previous location if available
        if (data.user.is_admin) {
          navigate("/admin/dashboard");
        } else {
          navigate("/");
        }
      } else {
        const errorData = await response.json();
        setError(errorData.detail || "Authentication failed!");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError("An error occurred, Please try again later.");
    }
  };

  return (
    <>
    <Grid container spacing={1}>
      {!isTablet && (
        <Grid item xs={0} sm={0} md={6} lg={8} sx={{backgroundColor: theme.palette.primary.main,}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100vh',
            }}
          >
            <Grid 
              container 
              spacing={2}
              justifyContent="center"
              alignItems="center"
            >
              {icons.map((iconData, index) => (
                <Grid key={index} item xs={4} md={2}>
                  <div className={`icon-animation icon-appear-${index + 1}`}>
                    <Box textAlign="center">
                      <img 
                        src={iconData.icon} 
                        alt={iconData.title} 
                        style={iconData.style}
                      />
                      <Typography variant="subtitle1" sx={{fontSize: "13px", fontFamily: "Inter", color: 'white'}}>
                        {iconData.title}
                      </Typography>
                    </Box>
                  </div>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Grid>
      )}

      <Grid item xs={12} sm={12} md={6} lg={4}>
        <div
          className="container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <div className="login-form" style={{ width: "380px" }}>
            <img
              src="./logo.png" // Path relative to the public folder
              alt="Auto Kalluigjeri Logo"
              style={{
                display: "block",
                margin: "0 auto",
                width: "100%", // Adjust as needed
              }}
            />
            <div className="row" style={{marginTop: '20px'}}>
              <div className="col-md-6 offset-md-3">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      required
                      type="text"
                      className="form-control"
                      id="username"
                      placeholder="Enter username"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        padding: "10px",
                        margin: "5px 0px",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      required
                      type="password"
                      className="form-control"
                      id="password"
                      placeholder="Enter password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      style={{
                        borderRadius: "5px",
                        border: "1px solid #ccc",
                        padding: "10px",
                        margin: "5px 0px",
                        boxSizing: "border-box",
                        width: "100%",
                      }}
                    />
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                    style={{
                      cursor: "pointer",
                      background: theme.palette.secondary.main,
                      color: theme.palette.primary.light,
                      borderRadius: "5px",
                      border: 0,
                      boxShadow: "none",
                      padding: "10px",
                      margin: "5px 0px",
                      boxSizing: "border-box",
                      width: "100%",
                    }}
                  >
                    {loading ? "Loading..." : "Login"}
                  </button>
                  <Stack sx={{ width: "100%", height: "48px" }} spacing={0}>
                    {error && (
                      <Alert
                        severity="error"
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: 300,
                        }}
                      >
                        {error}
                      </Alert>
                    )}
                    {warning && (
                      <Alert
                        severity="warning"
                        sx={{
                          fontFamily: "Inter",
                          fontWeight: 300,
                        }}
                      >
                        {warning}
                      </Alert>
                    )}
                  </Stack>
                </form>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <span
                style={{
                  marginRight: "10px",
                  fontSize: "14px",
                  fontFamily: "Inter",
                }}
              >
                Powered by
              </span>
              <Link
                    href="https://www.tecmotion.co"
                    target="_blank"
                    rel="noopener"
                    sx={{ ml: 1, color: "#fff" }}
                  >
              <img
                src="./tecm_logo.png"
                alt="Tecm Logo"
                style={{
                  width: "150px",
                }}
              />
              </Link>
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
    </>
  );
};

export default Login;
