import {
  Typography,
  Box,
  Autocomplete,
  TextField,
  Button,
} from "@mui/material";
import * as React from "react";
import theme from "../../../theme";
import { LoadingButton } from "@mui/lab";
import { getOilData, getOilParams } from "../../../services/core-api";
import { Count } from "../../../interfaces/DataSupplierFacetCounts";
import { CriteriaFilters } from "../../../interfaces/CriteriaFilters";
import { OilCriteria } from "../../../interfaces/OilCriteria";

const SelectEngineRequirements = () => {
  const frontendURL = process.env.REACT_APP_FRONTEND_URL;
  const [isLoadingData, setIsLoadingData] = React.useState(false);

  const [openMarker, setOpenMarker] = React.useState(false);
  const [selectedViscosity, setSelectedViscosity] = React.useState("");
  const loadingMarker = openMarker;

  const [brands, setBrands] = React.useState<Count[]>([]);
  const [selectedBrand, setSelectedBrand] = React.useState<Count | null>(null);

  const [openOemApprovel, setOpenOemApprovel] = React.useState(false);
  const [oemApprovels, setOemApprovels] = React.useState<OilCriteria[]>([]);
  const [selectedOemApprovel, setSelectedOemApprovel] =
    React.useState<OilCriteria | null>(null);
  const loadingOemApprovel = openOemApprovel && oemApprovels.length === 0;
  const [specifications, setSpecifications] = React.useState<
    readonly OilCriteria[]
  >([]);
  const [selectedSpecification, setSelectedSpecification] =
    React.useState<OilCriteria | null>(null);
  const loadingSpecification = openOemApprovel && oemApprovels.length === 0;

  const [criteriaFilters, setCriteriaFilters] = React.useState<
    CriteriaFilters[]
  >([]);
  const [page, setPage] = React.useState(1);

  React.useEffect(() => {
    let activeViscosity = true;

    (async () => {
      try {
        if (activeViscosity) {
          const options = {
            page: page,
          };

          const response = await getOilParams(options);
          if (response) {
            setBrands(response.dataSupplierFacets.counts);
            const acepaSpecification =
              response.groupedCriteria["Specification"];
            if (acepaSpecification) {
              setOemApprovels(acepaSpecification);
            }
            const specification = response.groupedCriteria["ACEA specification"];
            if (specification) {
              setSpecifications(specification);
            }
          }
        }
      } catch (error) {}
      return () => {
        activeViscosity = false;
      };
    })();
  }, [page]);

  const handleVicosityChange = async (
    event: React.ChangeEvent<{}>,
    viscosity: string | null
  ) => {
    if (viscosity) {
      setSelectedViscosity(viscosity);
      try {
        const options = {
          viscosity: viscosity,
          page: page,
        };
        const response = await getOilParams(options);
        if (response) {
          // setBrands(response.dataSupplierFacets.counts);
          // setOemApprovels(response.groupedCriteria["Specification"] || []);
          // setSpecifications(response.groupedCriteria["ACEA specification"] || []);
          setSelectedBrand(null);
          setSelectedOemApprovel(null);
          setSelectedSpecification(null);
        }
      } catch (error) {}
    }
  };

  const handleBrandChange = async (
    event: React.ChangeEvent<{}>,
    brand: Count | null
  ) => {
    if (brand) {
      setSelectedBrand(brand);
      try {
        const options = {
          viscosity: selectedViscosity,
          manufacturer: brand.dataSupplierId,
          page: page,
        };
        const response = await getOilParams(options);
        if (response) {
          // setOemApprovels(response.groupedCriteria["Specification"] || []);
          // setSpecifications(response.groupedCriteria["ACEA specification"] || []);
          setSelectedOemApprovel(null);
          setSelectedSpecification(null);
        }
      } catch (error) {}
    }
  };

  const handleOemApprovelChange = async (
    event: React.ChangeEvent<{}>,
    oemApprovel: OilCriteria | null
  ) => {
    if (oemApprovel) {
      setSelectedOemApprovel(oemApprovel);

      const updatedFilters = [
        ...criteriaFilters.filter(
          (c) => c.criteriaId !== oemApprovel.criteriaId
        ),
        {
          criteriaId: Number(oemApprovel.criteriaId),
          rawValue: oemApprovel.rawValue,
        },
      ];

      setCriteriaFilters(updatedFilters);
      try {
        const options = {
          viscosity: selectedViscosity,
          ...(selectedBrand && { manufacturer: selectedBrand.dataSupplierId }),
          criteria: updatedFilters,
          page: page,
        };

        await getOilData(options);
        
      } catch (error) {}
    }
  };

  const handleSpecificationChange = async (
    event: React.ChangeEvent<{}>,
    specification: OilCriteria | null
  ) => {
    if (specification) {
      setSelectedSpecification(specification);
      const updatedFilters = [
        ...criteriaFilters.filter(
          (c) => c.criteriaId !== specification.criteriaId
        ),
        {
          criteriaId: Number(specification.criteriaId),
          rawValue: specification.rawValue,
        },
      ];

      setCriteriaFilters(updatedFilters);
      try {
        const options = {
          viscosity: selectedViscosity,
          ...(selectedBrand && { manufacturer: selectedBrand.dataSupplierId }),
          criteria: updatedFilters,
          page: page,
        };
        
        await getOilParams(options);
        
      } catch (error) {}
    }
  };

  const handleSubmitData = () => {
    setIsLoadingData(true);
  
    const viscosity = selectedViscosity;
    const brandId = selectedBrand?.dataSupplierId;
    const oemApprovelId = selectedOemApprovel?.criteriaId;
    const oemApprovelValue = selectedOemApprovel?.rawValue;
    const specificationId = selectedSpecification?.criteriaId;
    const specificationValue = selectedSpecification?.rawValue;
  
    // Construct the dynamic URL with conditional inclusion of parameters
    const dynamicUrl = `${frontendURL}/search-oil?`
      + `${selectedViscosity ? `&viscosity=${viscosity}` : ''}`
      + `&category=1862`
      + `${brandId ? `&brand=${brandId}` : ''}`
      + `${oemApprovelId && oemApprovelValue ? `&criteria=${oemApprovelId}:${oemApprovelValue}` : ''}`
      + `${specificationId && specificationValue ? `&criteria=${specificationId}:${specificationValue}` : ''}`;
      setIsLoadingData(false);
    // Redirect to the constructed URL
    window.location.href = dynamicUrl;
  };

  return (
    <Box sx={{ padding: "10px" }}>
      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "13px",
          color: theme.palette.text.primary,
          m: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        Select Brands and Speed rating
      </Typography>
      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
        <Autocomplete
          options={viscosities}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                fontFamily: "Inter",
                "&:hover": {
                  backgroundColor: "#dcdcdc",
                },
                color: theme.palette.text.primary,
                padding: "10px",
                fontSize: "13px",
              }}
            >
              {option}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="SAE viscosity grade"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  height: "100%",
                  top: "-4px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  fontSize: "13px",
                  height: "40px",
                },
              }}
            />
          )}
          value={selectedViscosity}
          onChange={handleVicosityChange}
          sx={{
            flex: 1,
            marginTop: "5px",
            width: "100%",
            height: "40px",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
        />
        <Autocomplete
          options={brands}
          getOptionLabel={(option) => option.mfrName}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                fontFamily: "Inter",
                "&:hover": {
                  backgroundColor: "#dcdcdc",
                },
                color: theme.palette.text.primary,
                padding: "10px",
                fontSize: "13px",
              }}
            >
              {option.mfrName}
            </Box>
          )}
          onChange={handleBrandChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Brand"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  height: "100%",
                  top: "-4px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: "13px",
                },
              }}
            />
          )}
          sx={{
            flex: 1,
            marginTop: "5px",
            width: "100%",
            height: "40px",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
        />
      </Box>
      <Typography
        variant="body1"
        align="left"
        sx={{
          fontSize: "13px",
          color: theme.palette.text.primary,
          m: 1,
          display: "flex",
          alignItems: "center",
        }}
      >
        Select OEM approval and Specification
      </Typography>
      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
        <Autocomplete
          options={oemApprovels}
          getOptionLabel={(option) => option.formattedValue}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                fontFamily: "Inter",
                "&:hover": {
                  backgroundColor: "#dcdcdc",
                },
                color: theme.palette.text.primary,
                padding: "10px",
                fontSize: "13px",
              }}
            >
              {option.formattedValue}
            </Box>
          )}
          onChange={handleOemApprovelChange}
          value={selectedOemApprovel}
          renderInput={(params) => (
            <TextField
              {...params}
              label="OEM approval"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  height: "100%",
                  top: "-4px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0,
                  height: "40px",
                  fontSize: "13px",
                },
              }}
            />
          )}
          sx={{
            flex: 1,
            width: "100%",
            height: "40px",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
        />
        <Autocomplete
          options={specifications}
          getOptionLabel={(option) => option.formattedValue}
          onChange={handleSpecificationChange}
          value={selectedSpecification}
          renderOption={(props, option) => (
            <Box
              component="li"
              {...props}
              sx={{
                fontFamily: "Inter",
                "&:hover": {
                  backgroundColor: "#dcdcdc",
                },
                color: theme.palette.text.primary,
                padding: "10px",
                fontSize: "13px",
              }}
            >
              {option.formattedValue}
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Specification"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  height: "100%",
                  top: "-4px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: "13px",
                },
              }}
            />
          )}
          sx={{
            flex: 1,
            width: "100%",
            height: "40px",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
        />
      </Box>
      <Box sx={{ marginTop: 1 }}>
        {isLoadingData ? (
          <LoadingButton
            loading
            variant="contained"
            sx={{
              height: "38px",
              width: "100%",
              alignItems: "center", // Center items vertically
              justifyContent: "flex-end",
            }}
          >
            Submit
          </LoadingButton>
        ) : (
          <>
            <Button
              disabled={!selectedViscosity && !selectedBrand && !selectedOemApprovel && !selectedSpecification}
              sx={{
                width: "100%",
                textTransform: "capitalize",
                borderRadius:0,
                backgroundColor: "secondary.main",
                color: "text.secondary",
                ':hover': {
                  backgroundColor: 'secondary.main',
                  color: '#fff'
                }
              }}
              onClick={handleSubmitData}
            >
              Search
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

const viscosities = [
  "0W-20",
  "0W-30",
  "10W-30",
  "10W-40",
  "10W-50",
  "10W-60",
  "15W-40",
  "20W-50",
  "2T",
  "4T",
  "5W-30",
  "5W-40",
  "75W-80",
  "80W-85",
  "80W-90",
  "AT42",
  "ATX",
  "CVT",
  "DSG",
  "HBF4",
  "LDS",
  "LHM",
  "LMVM",
  "XLD",
];

export default SelectEngineRequirements;
