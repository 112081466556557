import * as React from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { fetchLiveArticles } from "../../../services/tecdocService";
import { Article } from "../../../interfaces/Article";
import { fetchLastSearchedArticles, storeSearchArticle } from "../../../services/core-api";
import Button from "../../ui/Button";

const SearchProduct = () => {
  const theme = useTheme();
  const [isLoading, setIsLoading] = React.useState(false);
  const [loadingArticles, setLoadingArticles] = React.useState(false);
  const [articles, setArticles] = React.useState<Article[]>([]);
  const [input, setInput] = React.useState("");
  const [staticArticles, setStaticArticles] = React.useState<Article[]>([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetchLastSearchedArticles();
        if (response) {
          setStaticArticles(response);
        }
      } catch (error) {
        console.error("Error fetching last searched articles:", error);
      }
    })();
  }, []);

  useEffect(() => {
    if (input.trim() !== '') {
      setLoadingArticles(true);
      fetchLiveArticles(input)
        .then((articles) => {
          const transformedArticles = transformResponseToArticle(articles);
          setArticles(transformedArticles);
          setLoadingArticles(false);
        })
        .catch(error => {
          console.error("Error fetching live articles:", error);
          setLoadingArticles(false);
        });
    } else {
      setArticles(staticArticles); // Show static articles if the input is empty
    }
  }, [input, staticArticles]);

  function transformResponseToArticle(articles: any): Article[] {
    return articles.map((article: any) => ({
      id: article.id,
      dataSupplierId: article.dataSupplierId,
      articleNumber: article.articleNumber,
      internalNumber: article.internalNumber,
      mfrName: article.mfrName,
      category: article.category,
      erp_id: article.erp_id,
      season: article.season,
      width: article.width || "",
      height: article.height || "",
      dimensions: article.dimensions || "",
      ampher: article.ampher || "",
      description: article.description || "No description available",
      price: article.price,
      open_status: article.open_status,
      depo_ambulantivGB: article.depo_ambulantivGB,
      depo_fushekosove: article.depo_fushekosove,
      depo_vranjevc: article.depo_vranjevc,
      depo_kryesore: article.depo_kryesore,
      depo_ferizaj: article.depo_ferizaj,
      depo_prizren: article.depo_prizren,
      depo_podujeve: article.depo_podujeve,
      genericArticles: article.genericArticles || [],
      searchQueryMatches: article.searchQueryMatches || [],
      images: article.images || [],
      articleCriteria: article.articleCriteria || [],
      gtins: article.gtins || [],
      misc: article.misc || {}
    }));
  }

  const handleSearch = async (option: any) => {
    setIsLoading(true);
    console.log(option);
    try {
      const searchArticle = {
        user_id: 1,
        articleNumber: input,
        dataSupplierId: option.dataSupplierId,
        ...(option.internalNumber && { internalNumber: option.internalNumber }), 
        mfrName: option.mfrName,
        category: `${option.genericArticles?.[0]?.genericArticleId || option.category}`,
      };
      
      const articleExists = staticArticles.some(
        (article) =>
          article.articleNumber === searchArticle.articleNumber ||
          article.internalNumber === searchArticle.internalNumber
      );

      if (!articleExists && (option.articleNumber || option.internalNumber)) {
        if (input.trim() !== '') {
        await storeSearchArticle(searchArticle);
        }
      }
  
      let dynamicUrl = '';
  
      // Check if internalNumber exists
      if (option.internalNumber) {
        dynamicUrl = `/search-internal?keyword=${option.internalNumber}`;
  
        // Check if brand (dataSupplierId) and category exist and append them if present
        if (option.dataSupplierId) {
          dynamicUrl += `&brand=${option.dataSupplierId}`;
        }
        if (option.category) {
          dynamicUrl += `&category=${option.category}`;
        }
      } else {
        if (option.articleNumber.startsWith("R-")) {
          dynamicUrl = `/search-internal?keyword=${option.articleNumber}`;
        }
        // If internalNumber does not exist, use articleNumber and go to /search-products
        dynamicUrl = `/search-products?keyword=${option.articleNumber}`;
  
        // Check if brand (dataSupplierId) and category exist and append them if present
        if (option.dataSupplierId) {
          dynamicUrl += `&brand=${option.dataSupplierId}`;
        }
        if (option.genericArticles?.[0]?.genericArticleId || option.category) {
          dynamicUrl += `&category=${option.genericArticles?.[0]?.genericArticleId || option.category}`;
        }
      }
  
      // Redirect to the dynamically constructed URL
      window.location.href = dynamicUrl;
  
    } catch (error) {
      console.error("Error during search:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const searchWithKeyword = async (input: string) => {
    if (!input) return;
    try {
      const searchArticle = { user_id: 1, articleNumber: input };
      await storeSearchArticle(searchArticle);

      const dynamicUrl = input.startsWith("R-")
        ? `/search-internal?keyword=${input}`
        : `/search-products?keyword=${input}`;

      window.location.href = dynamicUrl;
    } catch (error) {
      console.error("Error during keyword search:", error);
    }
  };

  const generateUniqueKey = (option: any) => `${option.dataSupplierId}-${option.articleNumber}-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <Box sx={{ flexGrow: { xs: 0, sm: 0, md: 1 }, height: "100%" }}>
      <Grid container spacing={0.5} sx={{ flexGrow: { xs: 0, sm: 0, md: 1 }, height: "100%" }}>
        <Grid item xs={10} sm={10} md={9} sx={{ height: "100%" }}>
          <Autocomplete
            freeSolo
            id="article-search"
            sx={{ height: "40px" }}
            getOptionLabel={(option: Article | string) => {
              if (typeof option === "string") return option;
              return option.internalNumber || option.articleNumber || '';
            }}
            filterOptions={(options, { inputValue }) => {
              const normalizedInputValue = inputValue.trim().toLowerCase().replace(/[\s-]/g, '');
              return options.filter(option => {
                const articleNumber = option.internalNumber || option.articleNumber;
                const normalizedArticleNumber = articleNumber.trim().toLowerCase().replace(/[\s-]/g, '');
                return normalizedArticleNumber.includes(normalizedInputValue);
              });
            }}
            options={articles}
            getOptionKey={(option) => generateUniqueKey(option)}
            renderOption={(props, option) => (
              <Box component="li" {...props} onClick={() => handleSearch(option)}>
                <Typography sx={{ fontSize: "12px" }}>
                  {option.mfrName}{" "}
                  {Array.isArray(option.genericArticles) && option.genericArticles.length > 0
                    ? option.genericArticles[0].genericArticleDescription
                    : ''}{" "}
                  <span style={{ fontWeight: "bold" }}>({option.articleNumber || "N/A"})</span>
                </Typography>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search by any number"
                InputLabelProps={{
                  ...params.InputLabelProps,
                  sx: {
                    color: theme.palette.text.primary,
                    fontSize: "13px",
                    fontFamily: "Inter",
                    height: "40px",
                    top: "-6px",
                    transform: "translate(14px, 1px), scale(1) !important",
                  },
                }}
                InputProps={{
                  ...params.InputProps,
                  onFocus: () => {
                    if (!input) setArticles(staticArticles);
                  },
                  onChange: (event) => {
                    setTimeout(() => {
                      setInput(event.target.value);
                    }, 1500);
                  },
                  sx: {
                    height: "38px",
                    fontSize: "13px",
                    padding: "0px !important",
                    fontFamily: "Inter",
                    borderRadius: 0,
                  },
                  endAdornment: (
                    <React.Fragment>
                      {loadingArticles ? (
                        <CircularProgress size={15} color="inherit" style={{ marginTop: "-6px", marginRight: "40px" }} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={2} sm={2} md={3}>
          {isLoading ? (
            <LoadingButton
              startIcon={<SearchIcon />}
              sx={{ 
                height: "38px", 
                backgroundColor: theme.palette.primary.main, 
                width: "100%", 
                color: theme.palette.common.white 
              }}
              loadingPosition="start"
              loading
              variant="outlined"
              disabled
            />
          ) : (
            <Button
              startIcon={<SearchIcon />}
              sx={{ 
                height: "38px", 
                borderRadius: 0, 
                background: theme.palette.secondary.main, 
                ":hover":{
                  background: theme.palette.secondary.main,
                },
                color: theme.palette.common.white, 
                width: "100%" 
              }}
              onClick={() => searchWithKeyword(input)}
            >
              Search
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchProduct;
