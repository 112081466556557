import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import {
  Stack,
  Box,
  Pagination,
  Grid,
  Typography,
  TextField,
  Button,
  InputAdornment,
  Drawer,
  Autocomplete,
} from "@mui/material";
import theme from "../../../theme";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import { createUser, getUsers, updateUser } from "../../../services/core-api";
import { UserRead } from "../../../interfaces/core/UserRead";
import { useSnackbar } from "../../../components/common/SnackbarContext";


const UsersList = () => {
  const [page, setPage] = React.useState(1);
  const rowsPerPage = 10;
  const [count, setCount] = React.useState(0);
  const [users, setUsers] = React.useState<UserRead[] | []>([]);
  const [searchValue, setSearchValue] = React.useState('');

  const fetchUsers = React.useCallback(async () => {
    try {
      const options = {
        keyword: searchValue,
        page_number: page
      };
      const response = await getUsers(options);
      if(response) {
        setUsers(response.users);
        setPage(response.page_number);
        setCount(Math.ceil(response.total / rowsPerPage));
      }
    } catch (error) {
      setUsers([]);
      setPage(1);
      setCount(0);
    }
  }, [page, rowsPerPage, searchValue]);

  React.useEffect(() => {
    fetchUsers(); // Only call fetchUsers without checking any conditions
  }, [fetchUsers]); // Only re-run when fetchUsers changes


  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    setPage(page);
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: "smooth",
    });
  };

  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  // State variables for each TextField
  const [username, setUsername] = React.useState("");
  const [name, setName] = React.useState("");
  const [surname, setSurname] = React.useState("");
  const [erp_id, setClientErpId] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [city, setCity] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [activate_grid_view, setActivateGridView] = React.useState(true);
  const [is_active, setIsActive] = React.useState(true);
  const [is_super_admin, setIs_super_admin] = React.useState(false);
  const [is_admin, setIsAdmin] = React.useState(false);
  const [is_client, setIsClient] = React.useState(true);
  const [zone, setZone] = React.useState("");
  const { showSnackbar } = useSnackbar();

  // Options for the Autocomplete
  const zoneOptions = [
    "Prishtine",
    "Fushe Kosove",
    "Prishtine (Kodra e Trimave)",
    "Peje",
    "Prizren",
    "Ferizaj",
  ];

  // Function to generate a random password
  const generatePassword = () => {
    const length = 12; // You can adjust the length of the password
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let generatedPassword = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      generatedPassword += charset.charAt(Math.floor(Math.random() * n));
    }
    setPassword(generatedPassword);
  };

  // Function to generate a random nickname
  const generateUsername = () => {
    const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const numbers = "0123456789";
    let generatedNickname = letters.charAt(
      Math.floor(Math.random() * letters.length)
    );
    for (let i = 0; i < 5; i++) {
      generatedNickname += numbers.charAt(
        Math.floor(Math.random() * numbers.length)
      );
    }
    setUsername(generatedNickname);
  };

  // Function to handle form submission
  const handleCreateUser = async () => {
    const userData = {
      username,
      name,
      surname,
      erp_id,
      company,
      city,
      country,
      address,
      phone,
      email,
      password,
      activate_grid_view,
      is_active,
      is_super_admin,
      is_admin,
      is_client,
      zone: zone || "Prishtine",
      retailPrice: false
    };

    try {
      const response = await createUser(userData);
      if (response) {
        window.location.reload();
      }
    } catch (error) {}
  };

  const handleStatusToggle = async (user: UserRead) => {
    try {
      // Toggle the current status
      user.is_active = !user.is_active;
  
      // Send a request to update the user's status on the server
      const response = await updateUser(user);
      if (response.status === 200) {
        showSnackbar('User updated successfully!', 'success');
      }
    } catch (error) {
      showSnackbar('Failed to update user status.', 'error');
    }
  };

  const DrawerList = (
    <Box sx={{ width: "100%", p: 3, bgcolor: "#f5f5f5" }} role="presentation">
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          container
          spacing={1}
          sx={{
            maxWidth: "600px",
            display: "flex",
            bgcolor: "#fff",
            border: "1px solid #f1f1f1",
            p: 2,
            mt: 2,
          }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Username"
              variant="outlined"
              sx={{
                width: "100%",
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 1,
                "&:hover": {
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.text.primary,
                },
              }}
              onClick={generateUsername}
            >
              Generate Username
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Name"
              variant="outlined"
              sx={{
                width: "100%",
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Surname"
              variant="outlined"
              sx={{
                width: "100%",
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={surname}
              onChange={(e) => setSurname(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Client ERP ID"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={erp_id}
              onChange={(e) => setClientErpId(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Company"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={company}
              onChange={(e) => setCompany(e.target.value)}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="City"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Country"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={country}
              onChange={(e) => setCountry(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="outlined-basic"
              placeholder="Address"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Phone"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-basic"
              placeholder="Email"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <TextField
              id="outlined-password"
              placeholder="Password"
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 0,
              }}
              inputProps={{
                sx: {
                  fontSize: "13px",
                  fontFamily: "Inter",
                  padding: "10px 14px",
                },
              }}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Button
              variant="outlined"
              sx={{
                width: "100%",
                mt: 1,
                float: "left",
                bgcolor: "#fff",
                color: theme.palette.text.primary,
                border: 1,
                "&:hover": {
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.text.primary,
                },
              }}
              onClick={generatePassword}
            >
              Generate Password
            </Button>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={is_client}
                    onChange={(e) => setIsClient(e.target.checked)}
                  />
                }
                label="Customer role"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={is_admin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                  />
                }
                label="Admin role"
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Autocomplete
              options={zoneOptions}
              getOptionLabel={(option) => option}
              value={zone}
              onChange={(event, newValue) => setZone(newValue || "Prishtine")}
              sx={{
                width: "100%",
                backgroundColor: "#fff",
                ".MuiInputBase-input": {
                  height: "10px",
                  marginTop: "0px",
                  marginBottom: "0px",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Select Zone"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    mt: 1,
                    float: "left",
                    bgcolor: "#fff",
                    color: theme.palette.text.primary,
                    border: 0,
                  }}
                  InputLabelProps={{
                    ...params.InputLabelProps,
                    sx: {
                      color: theme.palette.text.primary,
                      fontSize: "13px",
                      fontFamily: "Inter",
                      height: "100%",
                      top: "-6px",
                    },
                  }}
                  inputProps={{
                    ...params.inputProps, // Ensure proper spreading of inputProps
                    sx: {
                      height: "10px",
                      fontSize: "13px",
                      fontFamily: "Inter",
                    },
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              sx={{
                width: "100%",
                mt: 1,
                bgcolor: theme.palette.primary.dark,
                color: theme.palette.text.secondary,
                fontSize: "13px",
                "&:hover": {
                  bgcolor: theme.palette.primary.light,
                  color: theme.palette.text.primary,
                },
              }}
              onClick={handleCreateUser}
            >
              Create User
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              float: "left",
              fontFamily: "Inter",
              fontWeight: 600,
              color: theme.palette.text.primary,
              mb: 3,
            }}
          >
            Users List
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            id="outlined-basic"
            placeholder="Search Customers..."
            variant="outlined"
            sx={{
              width: { xs: "100%", sm: "100%", md: "20rem" },
              float: "left",
              bgcolor: "#fff",
              color: theme.palette.text.primary,
              border: 0,
            }}
            InputProps={{
              sx: {
                fontSize: "13px",
                fontFamily: "Inter",
              },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{ color: "#d5d5d5 !important", fontSize: "17px" }}
                  />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Button
            startIcon={<AddIcon />}
            sx={{
              float: "right",
              pt: 1.5,
              pl: 2,
              pr: 2,
              pb: 1.5,
              textTransform: "capitalize",
              bgcolor: theme.palette.primary.dark,
              color: theme.palette.text.secondary,
              ":hover": {
                bgcolor: theme.palette.primary.dark,
              },
            }}
            onClick={toggleDrawer(true)}
          >
            Add Customer
          </Button>
        </Grid>
      </Grid>
      <Drawer anchor="bottom" open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <Paper sx={{ width: "100%", overflow: "hidden", boxShadow: "none" }}>
        <TableContainer
          sx={{ maxHeight: 640, border: "1px solid #f1f1f1", p: 2 }}
        >
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
               
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  User ID
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  Username
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  Surname
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  Company
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  Country
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  City
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  Address
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  Phone
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{ fontFamily: "Inter", fontWeight: 600, pr: 0, pb:0 }}
                >
                  Admin role
                </TableCell>
               
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((row) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                    >
                
                          <TableCell align={"left"}>
                            {row.id}
                          </TableCell>
                          <TableCell align={"left"}>
                            {row.username}
                          </TableCell>
                          <TableCell align={"left"}>
                            {row.name}
                          </TableCell>
                          <TableCell align={"left"}>
                            {row.surname}
                          </TableCell>
                          <TableCell align={"left"}>
                            {row.company}
                          </TableCell>
                          <TableCell align={"left"}>
                            {row.country}
                          </TableCell>
                          <TableCell align={"left"}>
                            {row.city}
                          </TableCell>
                          <TableCell align={"left"}>
                            {row.address}
                          </TableCell>
                          <TableCell align={"left"}>
                            {row.phone}
                          </TableCell>
                          <TableCell align={"left"}>
                            <FormGroup>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={row.is_active}
                                    onChange={() => handleStatusToggle(row)}
                                  />
                                }
                                label={row.is_active ? "Active" : "Inactive"}
                              />
                            </FormGroup>
                          </TableCell>
                          <TableCell align={"left"}>
                            {row.is_admin ? (
                              <span style={{ color: "green" }}>Admin</span>
                            ): (
                              <span style={{ color: "green" }}>User</span>
                            )}
                          </TableCell>
                    
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Stack spacing={2}>
        <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 1 }}>
          <Pagination
                      count={count}
                      page={page}
                      variant="outlined"
                      shape="rounded"
                      onChange={handlePageChange}
                    />
        </Box>
      </Stack>
    </>
  );
};
export default UsersList;
