import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Grid, IconButton, Tooltip } from "@mui/material";
import theme from "../../../theme";
import ProductLinkingVehicle from "./ProductLinkingVehicles";
import ProductOENumbers from "./ProductOENumbers";
import { useSelector } from "react-redux";
import ProductPartList from "./productPartList/ProductPartList";
import ProductDetails from "./ProductDetails";
import StarBorderOutlinedIcon from "@mui/icons-material/StarBorderOutlined";
import GradeIcon from "@mui/icons-material/Grade";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { addFavoriteItem, createOfferItem } from "../../../services/core-api";
import { useSnackbar } from "../../common/SnackbarContext";


interface PriceState {
  retailPrice: boolean;
}

interface RootState {
  priceReducer: PriceState;
}


interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0.5 }}>
          <div>{children}</div>
        </Box>
      )}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ProductTabs = (props: any) => {
  const { article, qty, isFavorite } = props;
  const [value, setValue] = React.useState(-1);
  const genericArticles = article.genericArticles ? article.genericArticles : "";
  const oeNumbers = article.oemNumbers ? article.oemNumbers : "";
  const { showSnackbar } = useSnackbar();
  const [isFavoriteItem, setIsFavoriteItem] = React.useState<Boolean | null>(null);
  const retailPrice = useSelector(
    (state: RootState) => state.priceReducer.retailPrice
  );

  React.useEffect(() => {
    setIsFavoriteItem(isFavorite);
  }, []);

  const handleChange = (event: any, newValue: number) => {
    setValue(newValue);
  };

  const handleClick = (index: number) => {
    if (index === value) {
      setValue(-1);
    }
  };

  const handleAddToOffer = (article: any) => {
    if (article) {
      (async () => {
        try {
          const user = localStorage.getItem("user");
          if (user) {
            const parsedUser = JSON.parse(user);
            const user_id = parsedUser.id;
            const price = article.offerPrice;
            const total = price * qty;
            const offerItem = {
              user_id: user_id,
              articleNumber: article.articleNumber,
              mfrName: article.mfrName,
              description: article.description ? article.description : "",
              quantity: Number(qty),
              price: parseFloat(price),
              total_price: parseFloat(total.toString()).toFixed(2),
            };
            
            await createOfferItem(offerItem);

            showSnackbar("Product added to Offer successfully!", "success");
          }
        } catch (error) {
          showSnackbar("Failed to add product in Offer!", "error");
        }
      })();
    }
  };

  const handleAddToFavorite = (article: any) => {
    if (article) {
      (async () => {
        try {
          const favoriteItem = {
            product_id: article.productId ? article.productId : article.id,
            favorite_id: 0,
            articleNumber: article.articleNumber,
            mfrName: article.mfrName,
            dataSupplierId: article.dataSupplierId,
            image: article.images && article.images.length > 0 ? article.images[0].imageURL800 : "",
            description: article.description ? article.description : "",
            quantity: qty,
            price: article.price ?? 0,
            totalPrice: article.price ?? 0,
            available: true
          };
          await addFavoriteItem(favoriteItem);
          showSnackbar("Product added to Favorites successfully!", "success");
          setIsFavoriteItem((prev) => !prev);
        } catch (error) {
          showSnackbar("Product not added to Favorites!", "error");
        }
      })();
    }
  };

  return (
    <>
      {article ? (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              bgcolor: "#f1f1f1",
              minHeight: "25px",
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={value === -1 ? false : value}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              sx={{
                minHeight: "25px",
                ".MuiTab-label": {
                  textTransform: "capitalize !important",
                },
                ".MuiTabs-indicator": {
                  backgroundColor: theme.palette.primary.main,
                },
                ".MuiButtonBase-root": {
                  color: theme.palette.text.primary,
                  textTransform: "capitalize !important",
                  fontSize: "13px",
                  fontFamily: "Inter",
                  fontWeight: "normal",
                  minHeight: "25px",
                  padding: "0 5px",
                },
                ".Mui-selected": {
                  color: `${theme.palette.primary.main} !important`,
                  fontWeight: 500,
                },
              }}
            >
              <Tab
                disabled={!article.tec_doc_article}
                label="Details"
                {...a11yProps(0)}
                onClick={() => handleClick(0)}
              />
              <Tab
                disabled={!article.tec_doc_article}
                label="OE Numbers"
                {...a11yProps(1)}
                onClick={() => handleClick(1)}
              />
              <Tab
                disabled={!article.tec_doc_article}
                label="Linked Vehicle"
                {...a11yProps(2)}
                onClick={() => handleClick(2)}
              />
              <Tab
                disabled={!article.tec_doc_article}
                label="Part List"
                {...a11yProps(3)}
                onClick={() => handleClick(3)}
              />
            </Tabs>
            <Box>
              <Tooltip title="add to offer">
                <span>
                  <IconButton
                    disabled={!qty || !retailPrice}
                    color="primary"
                    aria-label="add to offer"
                    onClick={() => handleAddToOffer(article)}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                </span>
              </Tooltip>

              <Tooltip title="add to favorite list">
                <IconButton
                  color="primary"
                  aria-label="add to favorite list"
                  onClick={() => handleAddToFavorite(article)}
                >
                  {isFavorite ? (
                    <GradeIcon />
                  ) : (
                    <StarBorderOutlinedIcon />
                  )}
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
          {value !== -1 && (
            <Box>
              <CustomTabPanel value={value} index={0}>
                <Grid container spacing={0.5}>
                  <ProductDetails article={article} />
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid container spacing={0.1}>
                  <ProductOENumbers oeNumbers={oeNumbers} />
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Grid container spacing={0.1}>
                  <ProductLinkingVehicle
                    article={article}
                    genericArticles={genericArticles}
                  />
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={3}>
                <Grid container spacing={0.1}>
                  <ProductPartList genericArticles={genericArticles} />
                </Grid>
              </CustomTabPanel>
            </Box>
          )}
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box
            sx={{
              bgcolor: "#f1f1f1",
              minHeight: "25px",
              borderBottom: 1,
              borderColor: "divider",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Product tabs"
              sx={{
                minHeight: "25px",
                ".MuiTab-label": {
                  textTransform: "capitalize !important",
                },
                ".MuiTabs-indicator": {
                  backgroundColor: theme.palette.primary.main,
                },
                ".MuiButtonBase-root": {
                  color: theme.palette.text.primary,
                  textTransform: "capitalize !important",
                  fontSize: "13px",
                  fontFamily: "Inter",
                  fontWeight: "normal",
                  minHeight: "25px",
                  padding: "0 5px",
                },
                ".Mui-selected": {
                  color: `${theme.palette.primary.main} !important`,
                  fontWeight: 500,
                },
              }}
            >
              <Tab label="Details" {...a11yProps(0)} />
              <Tab label="OE Numbers" {...a11yProps(1)} sx={{ mr: 1.5 }} />
              <Tab label="Linked Vehicle" {...a11yProps(2)} />
              <Tab label="Part List" {...a11yProps(3)} />
            </Tabs>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProductTabs;
