import { Autocomplete, Box, Button, CircularProgress, Popper, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useSelector  } from "react-redux";
import theme from '../../../theme';
import {LinkingTargetState } from "../../../store/reducers/linkingTargetReducer";
import { LoadingButton } from '@mui/lab';
import { Manufacturer } from '../../../interfaces/Manufacturer';
import { ManufacturerModel } from '../../../interfaces/ManufacturerModels';
import { VehicleDetail } from '../../../interfaces/VehicleDetail';
import { fetchManufacturers, fetchModels, getLinkageTargets } from '../../../services/tecdocService';
import { storeSearchVehicle } from '../../../services/core-api';

const SelectVehicle = () => {
  const frontendURL = process.env.REACT_APP_FRONTEND_URL;
  const linkingTargetType = useSelector((state: { linkingTarget: LinkingTargetState}) => state.linkingTarget.linkingTargetType);

  const [openMarker, setOpenMarker] = React.useState(false);
  const [manufacturers, setManufacturers] = React.useState<readonly Manufacturer[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = React.useState<Manufacturer | null>(null);
  const loadingMarker = openMarker && manufacturers.length === 0;
  const [errorManufacturer, setErrorManufacturer] = React.useState<string | null>(null);

  const [manufacturerModels, setManufacturerModels] = React.useState<ManufacturerModel[]>([]);
  const [openModel, setOpenModel] = React.useState(false);
  const [isLoadingModel, setIsLoadingModel] = React.useState(false);
  const [selectedManufacturerModel, setSelectedManufacturerModel] = React.useState<ManufacturerModel | null>(null);
  const loadingModel = openModel && manufacturerModels.length === 0;
  const modelInputRef = React.useRef<HTMLInputElement>(null);

  const [openMotor, setOpenMotor] = React.useState(false);
  const [motors, setMotors] = React.useState<VehicleDetail[]>([]);
  const [selectedMotor, setSelectedMotor] = React.useState<VehicleDetail | null>(null);
  const [isLoadingCar, setIsLoadingCar] = React.useState(false);
  const loadingMotor = openMotor && motors.length === 0;
  const motorInputRef = React.useRef<HTMLInputElement>(null);
   const [isLoadingData, setIsLoadingData] = React.useState(false);

   React.useEffect(() => {
    let activeMarker = true;

    if (!loadingMarker) {
      return undefined;
    }

    (async () => {
      try {
        if (activeMarker) {
          const data = await fetchManufacturers(linkingTargetType);
          setManufacturers([...data]);
          setErrorManufacturer(null);
        }
      } catch (error) {
        console.error("Error fetching manufacturers:", error);
        setErrorManufacturer("Not able to fetch manufacturers. Please try again.");
      }
    })();

    return () => {
      activeMarker = false;
    };
  }, [linkingTargetType, loadingMarker, manufacturers]);

  React.useEffect(() => {
    if (!openMarker) {
      setManufacturers([]);
    }
  }, [openMarker]);

  // Inside the Autocomplete component for Manufacturer
  const handleManufacturerChange = async (event: React.ChangeEvent<{}>, manufacturer: Manufacturer | null) => {
    setSelectedManufacturer(manufacturer);
    setOpenModel(true);

    if (manufacturer) {
      try {
        setIsLoadingModel(true);
        const models = await fetchModels(manufacturer.manuId, linkingTargetType);
        setManufacturerModels(models);
      } catch (error) {
        console.error("Error fetching models:", error);
      } finally {
        setIsLoadingModel(false);
      }
    }
  };

  const handleModelChange = async (event: React.ChangeEvent<{}>, model: ManufacturerModel | null) => {
    setSelectedManufacturerModel(model);
    setOpenMotor(true);

    if(model && selectedManufacturer) {
      try {
        const manufacturerId = selectedManufacturer.manuId;
        setIsLoadingCar(true);
        
        const motors = await getLinkageTargets(manufacturerId, model.modelId, linkingTargetType);
        setMotors(motors);
      } catch (error) {
        console.error("Error fetching motors:", error);
      } finally {
        setIsLoadingCar(false);
      }
    }
  }

  const handleMotorChange = async (event: React.ChangeEvent<{}>, motor: VehicleDetail | null) => {
    setSelectedMotor(motor);
  }

// Add useEffect hook to focus on the model input when a manufacturer is selected
  React.useEffect(() => {
    if (selectedManufacturer && modelInputRef.current) {
      modelInputRef.current.focus();
    }
    
    if (selectedManufacturerModel && motorInputRef.current) {
      motorInputRef.current.focus();
    }
  }, [selectedManufacturer, selectedManufacturerModel]);

   const handleSubmitData = async () => {
      setIsLoadingData(true);
      const linkageTargetId = selectedMotor?.linkageTargetId;
      const linkageTargetType = selectedMotor?.linkageTargetType;
      if (linkageTargetId && linkageTargetType) {
        const authUser = localStorage.getItem('user');
        if (authUser){
          const user = JSON.parse(authUser);
          const searchedVehicle = {
            user_id: user.id,
            tec_doc_type_no: selectedMotor.linkageTargetId,
            linkage_target_type: linkageTargetType,
            vehicle_name: `${selectedMotor.mfrName} ${selectedMotor.vehicleModelSeriesName} ${selectedMotor.description ?? ''}`,
            vehicle_model_year: `${selectedMotor.beginYearMonth ?? ''} ${selectedMotor.endYearMonth ?? ''}`,
            performance: `${selectedMotor.kiloWattsTo}kW / ${selectedMotor.horsePowerTo}HP`,
            capacity:  `${selectedMotor.capacityCC}`,
            engine_code: selectedMotor.engines[0].code,
            created_at: new Date().toISOString()
          }
  
          try {
            await storeSearchVehicle(searchedVehicle);
            setIsLoadingData(false);
          } catch (error) {
            console.error('Error storing vehicle:', error);
         }
        }
  
        const url = `${frontendURL}/search-oil?linkageTargetId=${linkageTargetId}&linkageTargetType=${linkageTargetType}&assemblyGroupNodeIds=101994&category=3224`;
        window.location.href = url;
      }
   }

   return (
      <Box sx={{ padding: "10px", paddingRight: '0px' }}>
        <Typography variant="body1" align="left" sx={{ fontSize: "13px", color: theme.palette.text.primary, m: 1, display: 'flex', alignItems: 'center' }}>
         Select Manufacture 
      </Typography> 
      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
      <Autocomplete
          disablePortal
          id="markerOil"
          open={openMarker}
          onOpen={() => {
            setOpenMarker(true);
          }}
          onClose={() => {
            setOpenMarker(false);
          }}
          ListboxProps={{
            style: { maxHeight: 200, overflowY: "auto" },
          }}
          PaperComponent={(props) => {
            const anchorEl = document.getElementById("markerOil");
            const width = anchorEl ? `${anchorEl.offsetWidth + 50}px` : "auto";

            return (
              <Popper
                {...props}
                open={openMarker}
                anchorEl={anchorEl}
                placement="bottom-start"
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [-7, -10], // Adjust this value to move the Popper up or down
                    },
                  },
                ]}
                style={{
                  zIndex: 9999,
                  fontSize: '13px',
                  fontFamily: "Inter",
                  border: "1px solid #f1f1f1",
                  boxShadow: "2px 3px 3px #ccc",
                  position: "relative",
                  background: "#fff",
                  width: width,
                }}
              />
            );
          }}
          value={selectedManufacturer}
          isOptionEqualToValue={(option, value) => option.manuName === value.manuName}
          getOptionLabel={(option) =>( option.manuName)}
          options={manufacturers}
          loading={loadingMarker}
          sx={{
            width: "100%",
            mt: '5px',
            height: "40px",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
          onChange={handleManufacturerChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Manufacturer"
              autoFocus
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  height: "100%",
                  top: "-6px",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
                endAdornment: (
                  <React.Fragment>
                    {loadingMarker ? (
                      <CircularProgress
                        color="inherit"
                        size={15}
                        sx={{ marginTop: "-15px" }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />

        {errorManufacturer && (
          <Box sx={{width: "100%"}}>
            <Typography variant="body2" color="error" sx={{textAlign: "left"}}>
              {errorManufacturer}
            </Typography>
          </Box>
        )}
      </Box>
      <Typography variant="body1" align="left" sx={{ fontSize: "13px", color: theme.palette.text.primary, m: 1, display: 'flex', alignItems: 'center' }}>
         Select Model and Engine 
      </Typography>
      <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
      <Autocomplete
          disablePortal
          id="modelOil"
          open={openModel && !!selectedManufacturer}
          onOpen={() => {
            setOpenModel(true);
          }}
          onClose={() => {
            setOpenModel(false);
          }}
          ListboxProps={{
            style: { maxHeight: 200, overflowY: "auto" },
          }}
          PaperComponent={(props) => {
            const anchorEl = document.getElementById("modelOil");
            const width = anchorEl ? `${anchorEl.offsetWidth + 45}px` : "auto";

            return (
              <Popper
                {...props}
                open={openModel}
                anchorEl={anchorEl}
                placement="bottom-start"
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [-7, -10], // Adjust this value to move the Popper up or down
                    },
                  },
                ]}
                style={{
                  zIndex: 9,
                  fontSize: '13px',
                  fontFamily: "Inter",
                  border: "1px solid #f1f1f1",
                  boxShadow: "2px 3px 3px #ccc",
                  position: "relative",
                  background: "#fff",
                  width: width,
                }}
              />
            );
          }}
          value={selectedManufacturerModel}
          isOptionEqualToValue={(option, value) => option.modelname === value.modelname}
          renderOption={(props, option) => {
            const fromYear = option.yearOfConstrFrom
              ? `${option.yearOfConstrFrom}`.slice(4) + `.` + `${option.yearOfConstrFrom}`.slice(0, 4)
              : "";
            const toYear = option.yearOfConstrTo
              ? `${option.yearOfConstrTo}`.slice(4) + `.` + `${option.yearOfConstrTo}`.slice(0, 4)
              : "";
          
            // Do not destructure `key` from `props`
            return (
              <li
                {...props}  // Spread all props directly onto the <li> element
                key={option.modelId}  // Set the key prop separately
              >
                {option.modelname} {fromYear} - {toYear}
              </li>
            );
          }}
          
          getOptionLabel={(option) => {
            // Format the label as desired
            const fromYear = option.yearOfConstrFrom ? `${option.yearOfConstrFrom}`.slice(4) + `.` + `${option.yearOfConstrFrom}`.slice(0, 4) : '';
            const toYear = option.yearOfConstrTo ? `${option.yearOfConstrTo}`.slice(4) + `.` + `${option.yearOfConstrTo}`.slice(0, 4) : '';
            return `${option.modelname} ${fromYear} - ${toYear}`;
        }}
          options={manufacturerModels}
          loading={isLoadingModel}
          sx={{
            flex: 1,
            width: "100%",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
          onChange={handleModelChange}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={modelInputRef}
              label="Models" 
              autoFocus={!!selectedManufacturer}
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  height: "100%",
                  top: "-6px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: '13px',
                  fontFamily: "Inter",
                },
                endAdornment: (
                  <React.Fragment>
                    {isLoadingModel ? (
                      <CircularProgress
                        color="inherit"
                        size={15}
                        style={{ marginTop: "-15px" }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
        <Autocomplete
          disablePortal
          id="motorOil"
          open={openMotor && !!selectedManufacturerModel}
          onOpen={() => {
            setOpenMotor(true);
          }}
          onClose={() => {
            setOpenMotor(false);
          }}
          ListboxProps={{
            style: { maxHeight: 200, overflowY: "auto" },
          }}
          PaperComponent={(props) => {
            const anchorEl = document.getElementById("motorOil");
            const width = anchorEl ? `${anchorEl.offsetWidth + 45}px` : "auto";

            return (
              <Popper
                {...props}
                open={openMotor}
                anchorEl={anchorEl}
                placement="bottom-start"
                modifiers={[
                  {
                    name: "offset",
                    options: {
                      offset: [-7, -10], // Adjust this value to move the Popper up or down
                    },
                  },
                ]}
                style={{
                  zIndex: 9999,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  border: "1px solid #f1f1f1",
                  boxShadow: "2px 3px 3px #ccc",
                  position: "relative",
                  background: "#fff",
                  width: width,
                }}
              />
            );
          }}
          renderOption={(props, option) => {
            // Parse the year strings and format them as "MM.YYYY"
            const beginYearMonth = option.beginYearMonth ? `${option.beginYearMonth.slice(5)}.${option.beginYearMonth.slice(0, 4)}`: '';
            const endYearMonth = option.endYearMonth ? `${option.endYearMonth.slice(5)}.${option.endYearMonth.slice(0, 4)}`: '';
        
            return (
                <li {...props} key={option.linkageTargetId}>
                    {option.description} ({option.kiloWattsFrom} kW / {option.horsePowerFrom} hp)  ({beginYearMonth} - {endYearMonth})
                </li>
            );
          }}
          value={selectedMotor}
          isOptionEqualToValue={(option, value) => option.linkageTargetId === value.linkageTargetId}
          getOptionLabel={(option) => {
            const beginYearMonth = option.beginYearMonth ? `${option.beginYearMonth.slice(5)}.${option.beginYearMonth.slice(0, 4)}`: '';
            const endYearMonth = option.endYearMonth ? `${option.endYearMonth.slice(5)}.${option.endYearMonth.slice(0, 4)}`: '';

            // Return the formatted string
            return `${option.description} (${option.kiloWattsFrom} kW / ${option.horsePowerFrom} hp) (${beginYearMonth} - ${endYearMonth})`;
          }}
          options={motors.map((motor) => ({ ...motor, key: motor.linkageTargetId }))}
          loading={loadingMotor}
          sx={{
            flex:1,
            width: "100%",
            backgroundColor: "#fff",
            ".MuiInputBase-input": {
              height: "40px",
              marginTop: "0px",
              marginBottom: "0px",
              paddingTop: "0px",
              paddingBottom: "0px",
            },
          }}
          onChange={handleMotorChange}
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={motorInputRef}
              autoFocus={!!selectedManufacturerModel}
              label="Motors"
              InputLabelProps={{
                ...params.InputLabelProps,
                sx: {
                  color: theme.palette.text.primary,
                  fontSize: "13px",
                  fontFamily: "Inter",
                  height: "100%",
                  top: "-6px",
                  transform: "translate(24px, 12px), scale(1) !important",
                },
              }}
              InputProps={{
                ...params.InputProps,
                sx: {
                  borderRadius: 0, // Adjust the height as needed
                  height: "40px",
                  fontSize: "13px",
                  fontFamily: "Inter",
                },
                endAdornment: (
                  <React.Fragment>
                    {loadingMotor ? (
                      <CircularProgress
                        color="inherit"
                        size={15}
                        style={{ marginTop: "-15px" }}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Box>
      <Box>
        
      </Box>
      <Box sx={{marginTop: 1}}>
    {isLoadingData ? (
          <LoadingButton loading variant="contained"
              sx={{ 
                height: '38px',
                width: "100%",
                alignItems: 'center',  // Center items vertically
                justifyContent: 'flex-end'
              }} 
              >
                Submit
              </LoadingButton>
        ) :(
        <>
          <Button
          disabled={!selectedMotor}
            sx={{
              width: "100%",
              borderRadius:0,
              textTransform: 'capitalize',
              backgroundColor: "secondary.main",
              ":hover": {
                backgroundColor: "secondary.main",
              },
              color: "text.secondary",
            }}
            onClick={handleSubmitData}>
            Search
          </Button>
        </>
        )}
    </Box>
      </Box>
   )
}

export default SelectVehicle;