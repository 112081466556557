import * as React from "react";
import { Box, Grid, AppBar, Tabs, Tab } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import GroupIcon from "@mui/icons-material/Group";
import EuroIcon from "@mui/icons-material/Euro";
import LocalMallIcon from "@mui/icons-material/LocalMall";
import { LineChart } from "@mui/x-charts/LineChart";
import { getDashboardData } from "../../services/core-api";

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

const xLabels = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const DashboardPage = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = React.useState(0);
  const [tabIndexOrders, setTabIndexOrders] = React.useState(0);

  const [activeUsers, setActiveUsers] = React.useState(0);
  const [inactiveUsers, setInactiveUsers] = React.useState(0);
  const [totalOrders, setTotalOrders] = React.useState(0);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [monthlyOrders, setMonthlyOrders] = React.useState<number[]>([]);
  const [monthlyAmount, setMonthlyAmount] = React.useState<number[]>([]);
  const [monthlyUsersLogin, setMonthlyUsersLogin] = React.useState<number[]>([]);

  React.useEffect(() => {
    let activeDashboard = true;
    if (activeDashboard) {
      (async () => {
        try {
          const response = await getDashboardData();
          if (response) {
            setActiveUsers(response.active_users);
            setInactiveUsers(response.inactive_users);
            setTotalOrders(response.orders);
            setTotalAmount(response.total_amount);
            setMonthlyOrders(response.monthly_orders);
            setMonthlyAmount(response.monthly_amount);
            setMonthlyUsersLogin(response.monthly_logins);
          }
        } catch (error) {
          console.error("Failed to fetch dashboard data", error);
        }
      })();
    }

    return () => {
      activeDashboard = false;
    };
  }, []);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleOrdersTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabIndexOrders(newValue);
  };

  const handleTabChangeIndex = (index: number) => {
    setTabIndex(index);
  };

  const handleOrdersTabChangeIndex = (index: number) => {
    setTabIndexOrders(index);
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              bgcolor: "background.paper",
              width: "100%",
              border: "1px solid #f1f1f1",
            }}
          >
            <AppBar position="static" sx={{ boxShadow: "none" }}>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <GroupIcon fontSize="small" sx={{ mr: 0.5 }} /> Active Users
                    </Box>
                  }
                  {...a11yProps(0)}
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter",
                    textTransform: "capitalize",
                  }}
                />
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <GroupIcon fontSize="small" sx={{ mr: 0.5 }} /> Inactive Users
                    </Box>
                  }
                  {...a11yProps(1)}
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter",
                    textTransform: "capitalize",
                  }}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={tabIndex}
              onChangeIndex={handleTabChangeIndex}
            >
              <TabPanel value={tabIndex} index={0} dir={theme.direction}>
                <Box sx={{ fontSize: "20px", fontFamily: "Inter", fontWeight: 600 }}>
                  {activeUsers}
                </Box>
              </TabPanel>
              <TabPanel value={tabIndex} index={1} dir={theme.direction}>
                <Box sx={{ fontSize: "20px", fontFamily: "Inter", fontWeight: 600 }}>
                  {inactiveUsers}
                </Box>
              </TabPanel>
            </SwipeableViews>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              bgcolor: "background.paper",
              width: "100%",
              border: "1px solid #f1f1f1",
            }}
          >
            <AppBar position="static" sx={{ boxShadow: "none" }}>
              <Tabs
                value={tabIndexOrders}
                onChange={handleOrdersTabChange}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <LocalMallIcon fontSize="small" sx={{ mr: 0.5 }} /> Orders No
                    </Box>
                  }
                  {...a11yProps(0)}
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter",
                    textTransform: "capitalize",
                  }}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={tabIndexOrders}
              onChangeIndex={handleOrdersTabChangeIndex}
            >
              <TabPanel value={tabIndexOrders} index={0} dir={theme.direction}>
                <Box sx={{ fontSize: "20px", fontFamily: "Inter", fontWeight: 600 }}>
                  {totalOrders}
                </Box>
              </TabPanel>
            </SwipeableViews>
          </Box>
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <Box
            sx={{
              bgcolor: "background.paper",
              width: "100%",
              border: "1px solid #f1f1f1",
            }}
          >
            <AppBar position="static" sx={{ boxShadow: "none" }}>
              <Tabs
                value={0}
                indicatorColor="secondary"
                textColor="inherit"
                variant="fullWidth"
                aria-label="full width tabs example"
              >
                <Tab
                  label={
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <EuroIcon fontSize="small" sx={{ mr: 0.5 }} /> Total Amount
                    </Box>
                  }
                  {...a11yProps(0)}
                  sx={{
                    fontSize: "13px",
                    fontFamily: "Inter",
                    textTransform: "capitalize",
                  }}
                />
              </Tabs>
            </AppBar>
            <SwipeableViews axis={theme.direction === "rtl" ? "x-reverse" : "x"} index={0}>
              <TabPanel value={0} index={0} dir={theme.direction}>
                <Box sx={{ fontSize: "20px", fontFamily: "Inter", fontWeight: 600 }}>
                  {Number(totalAmount).toFixed(2)} LEK
                </Box>
              </TabPanel>
            </SwipeableViews>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={3} sx={{ mt: 3 }}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box sx={{ border: "1px solid #f1f1f1", bgcolor: "#fff", p: 2 }}>
            <LineChart
              width={500}
              height={300}
              series={[
                { data: monthlyOrders.map((order) => Number(order)), label: "Monthly Orders No" },
                { data: monthlyAmount.map((amount) => Number(amount)), label: "Monthly Amount" },
              ]}
              xAxis={[{ scaleType: "band", data: xLabels }]}
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box sx={{ border: "1px solid #f1f1f1", bgcolor: "#fff", p: 2 }}>
            <LineChart
              width={500}
              height={300}
              series={[{ data: monthlyUsersLogin.map((login) => Number(login)), label: "Monthly Users Login" }]}
              xAxis={[{ scaleType: "band", data: xLabels }]}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default DashboardPage;
