import * as React from "react";
import {
  Alert,
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Link,
  CardMedia,
  Checkbox,
  Grid,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Header from "../../components/common/header/Header";
import ClientSideMenu from "./ClientSideMenu";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import Slide, { SlideProps } from "@mui/material/Slide";
import Fade from "@mui/material/Fade";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import { TransitionProps } from "@mui/material/transitions";
import ProductTabs from "../../components/products/productTabs/ProductTabs";
import theme from "../../theme";
import { Article } from "../../interfaces/Article";

interface State extends SnackbarOrigin {
  openNotification: boolean;
}

function SlideTransition(props: SlideProps) {
  return <Slide {...props} direction="up" />;
}

const CustomIcon = styled(ContentCopyOutlinedIcon)({
  marginLeft: 10,
  marginRight: 0,
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "transparent",
  color: "#000",
  boxShadow: "none",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
}));

const PromotionsPage = () => {
  const [article, setArticle] = React.useState<Article | null>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openBasketNotification, setOpenBasketNotification] =
    React.useState(false);
  const [value, setValue] = React.useState(0);
  const defaultImage = "./no-image.png";

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  // Add article to basket
  const addToBasket = (
    article: Article,
    newStateBasketNotification: SnackbarOrigin
  ) => {
    setStateBasketNotification({
      ...newStateBasketNotification,
      openNotification: true,
    });
    setOpenBasketNotification(true);
  };

  const hideBasketNotification = () => {
    setStateBasketNotification({
      ...stateBasketNotification,
      openNotification: false,
    });
  };

  const [stateBasketNotification, setStateBasketNotification] =
    React.useState<State>({
      openNotification: false,
      vertical: "top",
      horizontal: "center",
    });

  const { vertical, horizontal, openNotification } = stateBasketNotification;

  const handleChange = (event: any) => {
    // Check if the input value is greater than the maximum number
    if (event.target.value !== "" && parseInt(event.target.value) > 100) {
      // If it exceeds the maximum, set the value to the maximum allowed
      setValue(200);
    } else if (parseInt(event.target.value) < 1) {
      // If it exceeds the maximum, set the value to the maximum allowed
      setValue(1);
    } else {
      // Otherwise, update the value normally
      setValue(event.target.value);
    }
  };

  const [state, setState] = React.useState<{
    open: boolean;
    Transition: React.ComponentType<
      TransitionProps & {
        children: React.ReactElement<any, any>;
      }
    >;
  }>({
    open: false,
    Transition: Fade,
  });

  const handleClick =
    (
      Transition: React.ComponentType<
        TransitionProps & {
          children: React.ReactElement<any, any>;
        }
      >
    ) =>
    () => {
      setState({
        open: true,
        Transition,
      });
    };

  const handleClose = () => {
    setState({
      ...state,
      open: false,
    });
  };
  return (
    <>
      <Header />
      <Box
        sx={{
          flexGrow: 1,
          m: 2,
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={2.5}>
            <ClientSideMenu />
            <Box sx={{ width: "100%", border: "4px solid #f1f1f1", mt: 2 }}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Paper
                    sx={{ width: "100%", boxShadow: "none", textAlign: "left" }}
                  >
                    <MenuList sx={{ ml: 1, mr: 1 }}>
                      <MenuItem>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Basket pads (1)
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                      <MenuItem>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Brake disc (11)
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                      <MenuItem>
                        <ListItemText>
                          <Typography
                            sx={{
                              fontSize: "15px",
                              fontFamily: "Inter",
                              fontWeight: 500,
                            }}
                          >
                            Stabilizer (3)
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    </MenuList>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={9.5}>
            <Box
              sx={{
                bgcolor: "#f1f1f1",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: 1,
                borderTop: `2px solid ${theme.palette.primary.dark}`,
              }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={7}
                  sx={{ paddingLeft: "0px !important" }}
                >
                  <Stack
                    spacing={2}
                    sx={{ paddingTop: "3px", paddingLeft: "5px" }}
                  >
                    <Breadcrumbs
                      separator={
                        <NavigateNextIcon
                          sx={{ color: "black" }}
                          fontSize="small"
                        />
                      }
                      aria-label="breadcrumb"
                    >
                      <Link
                        underline="hover"
                        key="2"
                        sx={{
                          color: theme.palette.text.primary,
                          ml: 1,
                          fontWeight: 400,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                        href="/"
                      >
                        Home
                      </Link>
                      ,
                      <Typography
                        key="3"
                        sx={{
                          color: theme.palette.text.primary,
                          fontWeight: 600,
                          fontSize: "15px",
                          fontFamily: "Inter",
                        }}
                      >
                        Promotions
                      </Typography>
                    </Breadcrumbs>
                  </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      pr: 1,
                    }}
                  >
                    <Box></Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  sx={{
                    border: "4px solid #f1f1f1",
                    marginBottom: 0.5,
                    padding: 0.5,
                  }}
                >
                  <Box
                    sx={{
                      background: "#f1f1f1",
                      pt: 0.5,
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        sx={{
                          minWidth: "40px",
                          "&:focus, &:active": {
                            backgroundColor: "transparent",
                          },
                        }}
                        startIcon={<CustomIcon />}
                        onClick={handleClick(SlideTransition)}
                      ></Button>
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Inter",
                          fontWeight: 500,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        {/* {article.articleNumber} */} 22546
                      </Typography>
                    </Item>
                    <Item
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "15px",
                          fontFamily: "Inter",
                          fontWeight: "normal",
                          display: "flex",
                          alignItems: "center",
                          mr: 2,
                        }}
                      >
                        27.50 LEK
                      </Typography>
                      <Button
                        startIcon={
                          <img
                            style={{ width: "27px", height: "27px" }}
                            src="./available_status.svg"
                            alt="product status icon"
                          />
                        }
                        sx={{
                          padding: "4px 2px !important",
                          // background: '#F33',
                          background: "#0C0",
                          "& .css-1d6wzja-MuiButton-startIcon": {
                            marginRight: "0px !important",
                            marginLeft: "0px !important",
                          },
                          borderRadius: 0,
                          ":hover": {
                            // background: '#F33',
                            background: "#0C0",
                          },
                        }}
                        onMouseEnter={handlePopoverOpen}
                        onMouseLeave={handlePopoverClose}
                      ></Button>
                      <Popover
                        id="mouse-over-popover"
                        sx={{
                          pointerEvents: "none",
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "center",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "center",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <MenuList
                          onMouseEnter={handlePopoverOpen}
                          onMouseLeave={handlePopoverClose}
                        >
                          <MenuItem>
                            <span
                              style={{
                                background: "#0C0",
                                width: "10px",
                                height: "10px",
                                borderRadius: "15px",
                              }}
                            ></span>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Inter",
                                fontWeight: 500,
                                display: "flex",
                                alignItems: "center",
                                ml: 1,
                              }}
                            >
                              Option 1
                            </Typography>
                          </MenuItem>
                          <MenuItem>
                            <span
                              style={{
                                background: "#0C0",
                                width: "10px",
                                height: "10px",
                                borderRadius: "15px",
                              }}
                            ></span>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Inter",
                                fontWeight: 500,
                                display: "flex",
                                alignItems: "center",
                                ml: 1,
                              }}
                            >
                              Option 1
                            </Typography>
                          </MenuItem>
                          <MenuItem>
                            <span
                              style={{
                                background: "#0C0",
                                width: "10px",
                                height: "10px",
                                borderRadius: "15px",
                              }}
                            ></span>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                fontFamily: "Inter",
                                fontWeight: 500,
                                display: "flex",
                                alignItems: "center",
                                ml: 1,
                              }}
                            >
                              Option 1
                            </Typography>
                          </MenuItem>
                        </MenuList>
                      </Popover>
                      <TextField
                        id="standard-number"
                        size="medium"
                        variant="standard"
                        value={value}
                        onChange={handleChange}
                        sx={{
                          width: "50px",
                          background: "#fff",
                          marginLeft: 2,
                          marginRight: 2,
                          "& .MuiInput-underline:before": {
                            borderBottom: "none", // Remove the bottom border before hover
                          },
                          "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                            {
                              borderBottom: "none", // Remove the bottom border on hover
                            },
                          "& .MuiInput-underline:after": {
                            borderBottom: "none", // Remove the bottom border after clicking
                          },
                        }}
                        InputProps={{
                          sx: {
                            textAlign: "center",
                            border: "1px solid #d4d4d4",
                            height: "36px",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            paddingLeft: ".9rem",
                            width: "100%",
                            "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                              {
                                borderBottom: "none", // Remove the bottom border on hover
                              },
                            "& .MuiInput-underline:after": {
                              borderBottom: "none", // Remove the bottom border after clicking
                            },
                          },
                        }}
                      />
                      <Button
                        aria-label="basket"
                        size="medium"
                        sx={{
                          background: theme.palette.secondary.main,
                          borderRadius: 0,
                          ":hover": {
                            backgroundColor: theme.palette.secondary.main,
                          },
                        }}
                        //   onClick={() =>
                        //     addToBasket(article, {
                        //       vertical: "top",
                        //       horizontal: "right",
                        //     })
                        //   }
                      >
                        <ShoppingCartOutlinedIcon
                          sx={{ color: "#fff" }}
                          fontSize="medium"
                        />
                      </Button>
                    </Item>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    <Item
                      sx={{
                        flex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        style={{ width: "100px" }}
                        src={defaultImage}
                        alt="Product"
                      />
                    </Item>
                    <Item
                      sx={{
                        flex: 11,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                      }}
                    >
                      <div>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          {/* {article.mfrName} */}
                          FEBI
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          {/* {article.genericArticles[0]?.genericArticleDescription} */}
                          Brake Pad Set
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          Found via{" "}
                          <b style={{ fontWeight: 500 }}>Article number</b> (
                          {/* {article.articleNumber}) */}
                          22546
                        </Typography>
                      </div>
                      <div>
                        <Typography
                          sx={{
                            textAlign: "left",
                            fontSize: "13px",
                            fontFamily: "Inter",
                            fontWeight: "normal",
                          }}
                        >
                          {/* {article.articleCriteria[0]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[0].criteriaDescription}
                          </b>
                          : {article.articleCriteria[0]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[1]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[1].criteriaDescription}
                          </b>
                          : {article.articleCriteria[1]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[2]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[2].criteriaDescription}
                          </b>
                          : {article.articleCriteria[2]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[3]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[3].criteriaDescription}
                          </b>
                          : {article.articleCriteria[3]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[4]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[4].criteriaDescription}
                          </b>
                          : {article.articleCriteria[4]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[5]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[5].criteriaDescription}
                          </b>
                          : {article.articleCriteria[5]?.formattedValue};{" "}
                        </>
                      )}
                      {article.articleCriteria[6]?.criteriaDescription && (
                        <>
                          <b style={{ fontWeight: 500 }}>
                            {article.articleCriteria[6].criteriaDescription}
                          </b>
                          : {article.articleCriteria[6]?.formattedValue};{" "}
                        </>
                      )} */}
                          Lorem ipsum
                        </Typography>
                      </div>
                    </Item>
                  </Box>
                  <Box sx={{ display: "flex" }}>
                    {/* <ProductTabs article={article} /> */}
                  </Box>
                </Grid>
              </Grid>
              <Snackbar
                open={state.open}
                onClose={handleClose}
                TransitionComponent={state.Transition}
                key={state.Transition.name}
                autoHideDuration={1200}
              >
                <Alert
                  onClose={handleClose}
                  severity="info"
                  sx={{ width: "100%" }}
                >
                  {/* {article.articleNumber} copied! */}
                  22546 copied!
                </Alert>
              </Snackbar>
              <Snackbar
                key={vertical + horizontal}
                anchorOrigin={{ vertical, horizontal }}
                open={openNotification}
                autoHideDuration={1500}
                onClose={hideBasketNotification}
              >
                <Alert
                  onClose={hideBasketNotification}
                  severity="success"
                  sx={{
                    bgcolor: "#fff",
                    width: "100%",
                    boxShadow: "4px 5px 5px #cfcfcf",
                  }}
                >
                  <Card sx={{ display: "flex", boxShadow: "none" }}>
                    <CardMedia
                      component="img"
                      sx={{ width: 120 }}
                      image={defaultImage}
                      alt="Live from space album cover"
                    />
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <CardContent sx={{ flex: "1 0 auto" }}>
                        <Typography
                          component="div"
                          variant="subtitle1"
                          sx={{
                            color: theme.palette.text.primary,
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          {/* {article.mfrName} */}
                          FEBI BILSTEIN
                          {/* {" "} */}
                          {/* {article.genericArticles[0]?.genericArticleDescription} */}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                          sx={{
                            textAlign: "left",
                            color: theme.palette.text.primary,
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          Quantity x 2
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          color="text.secondary"
                          component="div"
                          sx={{
                            textAlign: "left",
                            color: theme.palette.text.primary,
                            fontSize: "12px",
                            fontFamily: "Inter",
                            fontWeight: 500,
                          }}
                        >
                          Price: 27.50 LEK
                        </Typography>
                      </CardContent>
                    </Box>
                  </Card>
                </Alert>
              </Snackbar>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
export default PromotionsPage;
